import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpinnerComponent from './SpinnerComponent';
import ConstructionIcon from '@mui/icons-material/Construction';
import CampaignIcon from '@mui/icons-material/Campaign';
import IconButton from '@material-ui/core/IconButton';

const PageOverview = ({ tasks, screenshots, projectId, urlIds, projectData, updateProjectData, fetchTasks, socialRecommendations,loadingTimedOut, fetchUrlData }) => {
  const [userRole, setUserRole] = useState('');
  const [recommendationUpdateCheck, setRecommendationUpdateCheck] = useState(false);
  const [activeTab, setActiveTab] = useState('content');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [numberOfRefreshes, setNumberOfRefreshes] = useState(0);

  useEffect(() => {
    const fetchAndSetUserRole = async () => {
      const role = await fetchUserRoleForProject(projectId);
      setUserRole(role);
    };

    fetchAndSetUserRole();
    getRefreshesLeft(projectId);
  }, [projectId]);

  const fetchUserRoleForProject = async (projectId) => {
    try {
      const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
      if (response.status === 200) {
        return response.data.role;
      }
    } catch (error) {
      console.error('Error fetching user role for project:', error);
    }
  };

  const getRefreshesLeft = async (projectId) => {
    try {
      const response = await axios.get(`https://api.contentenhance.com/api/refreshes_left/${projectId}`, { withCredentials: true });
      if (response.status === 200) {
        setNumberOfRefreshes(response.data.number_of_refreshes);
      }
    } catch (error) {
      console.error('Error fetching number of refreshes left:', error);
    }
  };

  const refreshContentRecommendations = async () => {
    await refreshRecommendations('https://api.contentenhance.com/refresh_recommendations');
  };

  const refreshSocialRecommendations = async () => {
    await refreshRecommendations('https://api.contentenhance.com/refresh_social_recommendations');
  };

  const refreshRecommendations = async (endpoint) => {
    if (!['admin', 'editor'].includes(userRole)) {
      alert("You don't have permission to perform this action.");
      return;
    }
    const userConfirmed = window.confirm('Do you really want to refresh recommendations?');
    if (!userConfirmed) {
      return;
    }

    setIsRefreshing(true);
    const requestBody = {
      url_ids: urlIds,
      project_id: projectId,
    };

    try {
      const response = await axios.post(endpoint, requestBody, { withCredentials: true });

      if (response.status === 200) {
        waitForRecommendationsToUpdate();
        setNumberOfRefreshes(response.data.number_of_refreshes);
      } else {
        console.error('Failed to initiate refresh tasks:', response.data);
        setIsRefreshing(false);
      }
    } catch (error) {
      console.error('Error initiating refresh tasks:', error);
      setIsRefreshing(false);
    }
  };

  const waitForRecommendationsToUpdate = () => {
    const maxDuration = 300000;
    const pollingInterval = 30000;
    let elapsed = 0;

    const intervalId = setInterval(async () => {
      await checkForUpdatedRecommendations();
      if (recommendationUpdateCheck) {
        clearInterval(intervalId);
        setIsRefreshing(false);
        fetchTasks();
        fetchUrlData(); // Re-fetch the URL data to update the screenshot
      } else {
        elapsed += pollingInterval;
        if (elapsed >= maxDuration) {
          clearInterval(intervalId);
          setIsRefreshing(false);
        }
      }
    }, pollingInterval);
  };
  

  const checkForUpdatedRecommendations = async () => {
    try {
      const response = await axios.get(`https://api.contentenhance.com/api/recommendations/status?projectId=${projectId}`, { withCredentials: true });
      if (response.status === 200 && response.data.updated) {
        setRecommendationUpdateCheck(true);
      } else {
        setRecommendationUpdateCheck(false);
      }
    } catch (error) {
      console.error('Error checking for updated recommendations:', error);
      setRecommendationUpdateCheck(false);
    }
  };

  const tabStyle = {
    backgroundColor: '#374151',
    color: 'white',
    borderRadius: '8px 8px 0 0',
    marginRight: '5px',
    padding: '10px 20px',
    fontWeight: 'normal',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: '#1e3a8a',
    color: 'white',
    borderBottom: 'none',
  };

  return (
    <div className="flex w-full bg-gray-900 mb-8 min-w-[1200px]">
      <div className="w-2/3 p-8 mx-auto">
        <h2 className="text-base mb-4 text-center">Latest Screenshot on Top Device</h2>
        <div className="flex justify-center">
          <div className="overflow-y-auto max-h-[2000px]">
            {screenshots.latest_screenshot ? (
              <img src={screenshots.latest_screenshot} alt="Latest Screenshot" style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
              <p>No screenshot available</p>
            )}
          </div>
        </div>
      </div>
      <div className="w-1/3 p-8">
        <div className="flex mb-4 text-sm">
          <button
            style={activeTab === 'content' ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab('content')}
          >
		<IconButton size="small" style={{ color: 'white' }}>
            <ConstructionIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
			</IconButton>
            Content ideas
          </button>
          <button
            style={activeTab === 'tiktok' ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab('tiktok')}
          >
			<IconButton size="small" style={{ color: 'white' }}>
            <CampaignIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
			</IconButton>
            Social ideas
          </button>
        </div>

        {activeTab === 'content' ? (
          <>
            {/* Content Ideas Tab */}
            <ul className="list-disc pl-5 text-sm mt-8">
              {tasks.filter(task => task.recommendation_status === 'pending').map(task => (
                <li key={task.task_id} className="mb-8">{task.recommendations}</li>
              ))}
            </ul>
			  {loadingTimedOut && tasks.length === 0 && (
			    <p className="text-red-500 text-sm text-center mb-4">Recommendations failed. Please refresh</p>
			  )}
            <div className="flex justify-center">
              {isRefreshing ? (
                <SpinnerComponent />
              ) : (
                <button
                  className={`p-2 rounded text-sm h-[35px] text-white ${numberOfRefreshes > 0 ? 'bg-blue-500 hover:bg-blue-900' : 'bg-blue-300 cursor-not-allowed'}`}
                  onClick={refreshContentRecommendations}
                  disabled={numberOfRefreshes <= 0}
                >
                  Refresh recommendations
                </button>
              )}
            </div>
            <div className="text-sm flex justify-center mt-4">
              {numberOfRefreshes > 0 ? (
                <span className="italic">
                  You have {numberOfRefreshes} refreshes left this month.
                </span>
              ) : (
                <span className="italic">
                  You have no refreshes left this month.
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Social Ideas Tab */}
			{socialRecommendations ? (
			      <ul className="list-disc pl-5 text-sm mt-8">
			        {socialRecommendations.split('. ').map((recommendation, index) => (
			          <li key={index} className="mb-8">{recommendation.trim()}</li>
			        ))}
			      </ul>
			    ) : (
			      <p className="text-sm mb-8 mt-8">No social recommendations available.</p>
			    )}
			    <div className="flex justify-center">
			      {isRefreshing ? (
			        <SpinnerComponent />
			      ) : (
			        <button
			          className={`p-2 rounded text-sm h-[35px] text-white ${numberOfRefreshes > 0 ? 'bg-blue-500 hover:bg-blue-900' : 'bg-blue-300 cursor-not-allowed'}`}
			          onClick={refreshSocialRecommendations}
			          disabled={numberOfRefreshes <= 0}
			        >
			          Refresh social recommendations
			        </button>
			      )}
			    </div>
			    <div className="text-sm flex justify-center mt-4">
			      {numberOfRefreshes > 0 ? (
			        <span className="italic">
			          You have {numberOfRefreshes} refreshes left this month.
			        </span>
			      ) : (
			        <span className="italic">
			          You have no refreshes left this month.
			        </span>
			      )}
			    </div>
			  </>
			)}
      </div>
    </div>
  );
};

export default PageOverview;
