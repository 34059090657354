import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';



const CookieManager = () => {
	const [cookies, setCookies] = useState([]);
	  const [userRole, setUserRole] = useState('');
	  const [feedbackMsg, setFeedbackMsg] = useState('');
	  const [error, setError] = useState('');
	  const [showMobileModal, setShowMobileModal] = useState(false);
	  const navigate = useNavigate();
	  const { projectId } = useParams();
  
  useEffect(() => {
      const checkAuthentication = async () => {
        try {
          const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
          if (!response.data.isAuthenticated) {
            navigate('/login'); // Redirect to login if not authenticated
          }
        } catch (error) {
          console.error('Authentication check failed:', error);
          navigate('/login'); // Redirect to login on error
        }
      };

      checkAuthentication();
    }, [navigate]);
  
  
    useEffect(() => {
      const fetchCookies = async () => {
        if (!projectId) return;

        try {
          const response = await axios.get(`https://api.contentenhance.com/api/fetch-cookies/${projectId}`, {
            withCredentials: true // if you need to handle credentials like cookies or auth tokens
          });
          setCookies(response.data.cookies || []); // Assuming the API response structure has a 'cookies' field
        } catch (error) {
          console.error('Error fetching cookies:', error);
          // Handle the error appropriately
        }
      };

      fetchCookies();
    }, [projectId]); // Refetch when projectId changes
	
	useEffect(() => {
	    const handleResize = () => {
	      if (window.innerWidth < 1200) {
	        setShowMobileModal(true);
	      } else {
	        setShowMobileModal(false);
	      }
	    };

	    // Initial check
	    handleResize();

	    // Listen for resize events
	    window.addEventListener('resize', handleResize);

	    // Cleanup the event listener on unmount
	    return () => {
	      window.removeEventListener('resize', handleResize);
	    };
	  }, []);
	  
	  const validateCookie = (cookie) => {
	    if (!cookie.name.trim()) {
	      return 'Cookie name is required.';
	    }
	    if (!cookie.domain.trim()) {
	      return 'Cookie domain is required.';
	    }
	    if (cookie.sameSite && !['Lax', 'Strict', 'None'].includes(cookie.sameSite)) {
	      return 'Invalid SameSite value.';
	    }
	    if (cookie.expiration && isNaN(Date.parse(cookie.expiration))) {
	      return 'Invalid expiration date.';
	    }
	    return null; // No validation error
	  };
	  
	  const saveAllCookies = () => {
	    let hasError = false;

	    cookies.forEach((cookie, index) => {
	      const validationError = validateCookie(cookie);
	      if (validationError) {
	        handleSubmissionFeedback(`Error in cookie ${index + 1}: ${validationError}`, true);
	        hasError = true;
	      }
	    });

	    // Stop if validation errors are found
	    if (hasError) return;

	    // Save cookies individually
	    cookies.forEach(submitCookie);
	  };
	  

  
  const handleSubmissionFeedback = (message, isError = false) => {
      if (isError) {
        setError(message);
        setTimeout(() => setError(''), 5000); // Clears the error message after 5 seconds
      } else {
        setFeedbackMsg(message);
        setTimeout(() => setFeedbackMsg(''), 5000); // Clears the feedback message after 5 seconds
      }
    };
  

	const addCookie = () => {
	  if (cookies.some((cookie) => !cookie.name.trim())) {
	    handleSubmissionFeedback('Please complete existing cookie before adding another.', true);
	    return;
	  }

	  setCookies([
	    ...cookies,
	    { name: '', value: '', domain: '', path: '', expiration: '', secure: false, httpOnly: false, sameSite: '' },
	  ]);
	};
  
	const submitCookie = async (cookie, index) => {
	  // Check if the user has the required role
	  if (!['admin', 'editor'].includes(userRole)) {
	    handleSubmissionFeedback("You don't have permission to perform this action.", true);
	    return;
	  }

	  // Validate the cookie fields
	  const validationError = validateCookie(cookie);
	  if (validationError) {
	    handleSubmissionFeedback(`Cookie validation failed: ${validationError}`, true);
	    return;
	  }

	  // Proceed with API call to save the cookie
	  try {
	    const response = await axios.post(
	      `https://api.contentenhance.com/api/cookies/${projectId}`,
	      cookie,
	      {
	        withCredentials: true,
	        headers: { 'Content-Type': 'application/json' },
	      }
	    );

	    if (response.data?.cookie_id) {
	      const updatedCookies = [...cookies];
	      updatedCookies[index].cookie_id = response.data.cookie_id;
	      setCookies(updatedCookies);
	      handleSubmissionFeedback('Cookie saved successfully!');
	    } else {
	      handleSubmissionFeedback('Unexpected response from server.', true);
	    }
	  } catch (error) {
	    console.error('Error saving cookie:', error);
	    // Extract error message from API response, if available
	    const apiErrorMessage =
	      error.response?.data?.message || 'Failed to save cookie. Please try again.';
	    handleSubmissionFeedback(apiErrorMessage, true);
	  }
	};
  
  
  const commonStyles = "flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 cursor-pointer";
  
  const ExpiryDatePicker = React.memo(({ cookie, updateCookie, index }) => {
    const [localExpiration, setLocalExpiration] = useState(cookie.expiration || '');
    const [showInput, setShowInput] = useState(false);

    // Handle input change locally
    const handleChange = (e) => {
      setLocalExpiration(e.target.value);
    };

    // Commit changes to global state and hide input
    const handleBlur = () => {
      if (localExpiration) {
        updateCookie(index, 'expiration', localExpiration);
      }
      setShowInput(false);
    };

    return (
      <span className={commonStyles} style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
        {showInput || localExpiration ? (
          <input
            className="text-black"
            type="datetime-local"
            value={localExpiration}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ flexGrow: 1 }}
          />
        ) : (
          <span
            onClick={() => setShowInput(true)}
            style={{ cursor: 'pointer' }}
          >
            Expiry date
          </span>
        )}
      </span>
    );
  });
  
  

  const updateCookie = (index, field, value) => {
    const newCookies = [...cookies];
    newCookies[index][field] = value;
    setCookies(newCookies);
  };

  const deleteCookie = (index) => {
      if (!['admin', 'editor'].includes(userRole)) {
        alert("You don't have permission to perform this action.");
        return;
      }
      const cookie = cookies[index];
      if (cookie.cookie_id) {
        axios.delete(`https://api.contentenhance.com/api/delete-cookies/${cookie.cookie_id}`, {
          withCredentials: true,
        })
        .then(response => {
          const newCookies = [...cookies];
          newCookies.splice(index, 1);
          setCookies(newCookies);
          handleSubmissionFeedback('Cookie deleted successfully');
        })
        .catch(error => {
          console.error('Error:', error);
          handleSubmissionFeedback('Failed to delete cookie', true);
        });
      }
    };
  const fetchUserRoleForProject = async (projectId) => {
      try {
          const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
          if (response.status === 200) {
              return response.data.role;
          }
      } catch (error) {
          console.error('Error fetching user role for project:', error);
          // Handle error (e.g., user not authenticated or doesn't have a role in the project)
      }
  };
  
  useEffect(() => {
    const fetchAndSetUserRole = async () => {
      // Assuming you have projectId and a function to fetch the role
      const role = await fetchUserRoleForProject(projectId);
      setUserRole(role);
    };

    fetchAndSetUserRole();
  }, [projectId]); // Re-fetch if projectId changes
  

  return (
    <div className="flex flex-col h-screen bg-darkBlue">
      <TopBar />
      <div className="flex h-screen bg-lighterDarkBlue text-white">
        <Sidebar />
        <div className="flex-1 p-10 overflow-auto">
          <h1 className="text-base mb-2">Cookie manager (beta)</h1>
	  <p className="text-sm mb-8">Add cookies below that will then apply to our image capturing service, so it can access specific sections of your website (behind a login for example).</p>
	{showMobileModal && (
	        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
	          <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
	            <button 
	              onClick={() => setShowMobileModal(false)} 
	              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
	            >
	              &times;
	            </button>
	            <h2 className="text-center text-gray-200 text-lg z-[1300]">Scroll right after adding cookie</h2>
	            <p className="text-center text-gray-400 text-sm z-[1300]">
	               The experience is currently ideally suited for larger screens, but scroll right for all cookie fields when you add a cookie.
	            </p>
	            <div className="flex justify-center mt-4">
	              <button 
	                onClick={() => setShowMobileModal(false)} 
	                className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
	              >
	                Close
	              </button>
	            </div>
	          </div>
	        </div>
	      )}
          {cookies.map((cookie, index) => (
            <div key={index} className="flex gap-4 mb-4 text-gray-700 min-w-[1200px]">
              <input className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900" placeholder="Name" value={cookie.name} onChange={(e) => updateCookie(index, 'name', e.target.value)} />
              <input className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900" placeholder="Value" value={cookie.value} onChange={(e) => updateCookie(index, 'value', e.target.value)} />
              <input className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900" placeholder="Domain" value={cookie.domain} onChange={(e) => updateCookie(index, 'domain', e.target.value)} />
              <input className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900" placeholder="Path" value={cookie.path} onChange={(e) => updateCookie(index, 'path', e.target.value)} />
		  <ExpiryDatePicker cookie={cookie} updateCookie={updateCookie} index={index} />
              <select className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900" value={cookie.sameSite} onChange={(e) => updateCookie(index, 'sameSite', e.target.value)}>
                <option value="" disabled selected>Same site</option> 
				<option value="Lax">Lax</option>
                <option value="Strict">Strict</option>
                <option value="None">None</option>
              </select>
              <input type="checkbox" checked={cookie.secure} onChange={(e) => updateCookie(index, 'secure', e.target.checked)} /><span className="text-sm text-white py-2"> Secure</span>
              <input type="checkbox" checked={cookie.httpOnly} onChange={(e) => updateCookie(index, 'httpOnly', e.target.checked)} /> <span className="text-sm text-white py-2">HttpOnly</span>
              <button className="bg-red-500 text-white p-2 text-sm rounded-md hover:bg-red-700" onClick={() => deleteCookie(index)}>Delete</button>
            </div>
          ))}
          <button className="bg-gray-700 text-white p-2 mt-4 text-sm rounded-md hover:bg-gray-900" onClick={addCookie}>+ Add Cookie</button>
		  <div className="text-sm flex justify-center mt-8">
		<button
		  className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
		  onClick={saveAllCookies}
		>
		  Save All
		</button>
		  </div>
		  <div className="mt-4 justify-center flex block">
		  {feedbackMsg && <div className="alert alert-success justify-center block text-sm text-white">{feedbackMsg}</div>}
		            {error && <div className="alert alert-danger justify-center block text-sm text-white">{error}</div>}
					
		  </div>
        </div>
		
      </div>
    </div>
  );
}

export default CookieManager;
