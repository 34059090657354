import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Sparklines, SparklinesLine } from 'react-sparklines';
// Other necessary imports (e.g., for the bar chart)
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import Calendar from './Calendar'
import Checklist from './Checklist'
import BarChartComponent from './BarChartComponent'
import ReadingTimeChartComponent from './ReadingTimeChartComponent'
import NextDestinationsOverTime from './NextDestinationsOverTime';
import ReferralSourcesOverTime from './ReferralSourcesOverTime';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'; // This is the question mark icon
import NumbersIcon from '@mui/icons-material/Numbers'; // for prioritity
import TimelineIcon from '@mui/icons-material/Timeline'; // enagement rate
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // engagement time
import PendingActionsIcon from '@mui/icons-material/PendingActions'; // for action
import AnalyticsIcon from '@mui/icons-material/Analytics'; // sessions
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'; // top device
import SourceIcon from '@mui/icons-material/Source'; // source
import QueryStatsIcon from '@mui/icons-material/QueryStats'; // top query
import LinkIcon from '@mui/icons-material/Link'; // url
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PageOverview from './PageOverview';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Arrow icon
import SankeyDiagramComponent from './SankeyDiagramComponent'; // Import the new Sankey component
import DonutChartComponent from './DonutChartComponent'; // Import the DonutChart component
import Modal from '@material-ui/core/Modal';  // Import Material-UI's Modal for the popup

import AirIcon from '@mui/icons-material/Air';
import GroupsIcon from '@mui/icons-material/Groups';
import SendIcon from '@mui/icons-material/Send';
import StartIcon from '@mui/icons-material/Start';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { makeStyles } from '@material-ui/core/styles';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: '#333', // Dark grey background
        color: '#fff', // White text
        padding: theme.spacing(4),
        borderRadius: '8px',
        outline: 'none',
        boxShadow: theme.shadows[5],
    },
    buttonClose: {
        backgroundColor: '#8B0000', // Dark red
        color: '#fff',
        '&:hover': {
            backgroundColor: '#a52a2a', // Lighter red on hover
        },
    },
    buttonUpgrade: {
        backgroundColor: '#1E90FF', // Blue
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4169E1', // Darker blue on hover
        },
    },
}));


// Define a fixed cell width
const cellStyle = {
    width: '16%', // Set each cell to occupy roughly 1/6th of the table width
    borderBottom: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '10px',
};

const cellStylenormal = {
    width: '16%', // Set each cell to occupy roughly 1/6th of the table width
    borderBottom: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '10px',
};

const iconStyle = {
  fontSize: '.875rem', // Small icon size
};

const tabStyle = {
    backgroundColor: '#374151',
    color: 'white',
    borderRadius: '8px 8px 0 0',
    marginRight: '5px',
    padding: '10px 20px',
    fontWeight: 'normal',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
};

const activeTabStyle = {
    ...tabStyle,
    backgroundColor: '#1e3a8a',
    color: 'white',
    borderBottom: 'none',
};

const URLPage = () => {
    const classes = useStyles();
    const { projectId, urlId } = useParams();
    const [errorMessage, setErrorMessage] = useState('');
    const [urlData, setUrlData] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [isScreenshotAvailable, setIsScreenshotAvailable] = useState(false);
    const [projectData, setProjectData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage the popup visibility
    const navigate = useNavigate(); // Hook to programmatically navigate
	const [socialRecommendations, setSocialRecommendations] = useState('');
    const [activeTab, setActiveTab] = useState('engagement'); // Track the active tab
	const [activeComponentTab, setActiveComponentTab] = useState('inspiration');
	const [showMobileModal, setShowMobileModal] = useState(false);
	const [loadingTimedOut, setLoadingTimedOut] = useState(false);
	
	
	useEffect(() => {
	    const handleResize = () => {
	      if (window.innerWidth < 1200) {
	        setShowMobileModal(true);
	      } else {
	        setShowMobileModal(false);
	      }
	    };

	    // Initial check
	    handleResize();

	    // Listen for resize events
	    window.addEventListener('resize', handleResize);

	    // Cleanup the event listener on unmount
	    return () => {
	      window.removeEventListener('resize', handleResize);
	    };
	  }, []);
	
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/user', { withCredentials: true });
                const userData = response.data;
                if (userData.plan === 'free') {
                    const updatedDate = new Date(userData.personal_plan_updated);
                    const currentDate = new Date();
                    const expiryDate = new Date(updatedDate);
                    expiryDate.setDate(updatedDate.getDate() + 7);

                    if (currentDate > expiryDate) {
                        setIsPopupOpen(true); // Show the popup if the plan has expired
                    }
                }
            } catch (error) {
                console.error('Failed to fetch user details:', error);
                setErrorMessage('Failed to load user details');
            }
        };

        checkAuthentication();
        fetchUserDetails();
    }, [navigate]);
	
	useEffect(() => {
	  const timer = setTimeout(() => {
	    setLoadingTimedOut(true);
	  }, 300000); // 5 minutes in milliseconds
  
	  return () => clearTimeout(timer);
	}, []);
	
	
	const handleClosePopup = () => {
	        setIsPopupOpen(false);
	    };
	
	const updateProjectData = (updatedData) => {
	  setProjectData(updatedData);
	};
    
    const oddRowStyle = {
            backgroundColor: '#191A23'// Light gray background for odd rows
       };
       const evenRowStyle = {
           backgroundColor: 'rgb(18 24 39 / var(--tw-bg-opacity))' // White background for even rows
       };
	   
	   useEffect(() => {
	       axios.get(`https://api.contentenhance.com/api/projects/${projectId}`, {
	           withCredentials: true
	       })
	       .then(response => {
	           console.log("API response for project data:", response.data);
	           setProjectData(response.data);
	       })
	       .catch(error => {
	           console.error('Error fetching project data:', error);
	           // Handle errors, e.g., unauthorized access, project not found, etc.
	       });
	   }, [projectId]); // Re-fetch if projectId changes
	
	   const fetchTasks = async () => {
	           try {
	               const response = await axios.get(`https://api.contentenhance.com/api/urls/${projectId}/${urlId}/tasks`);
	               const tasksData = response.data;
	               setTasks(tasksData); // Update the tasks state

	               // Your existing logic...
	               if (tasksData.length > 0) {
	                   console.log("Tasks exist, updating the page as needed.");
	               } else {
	                   setTimeout(fetchTasks, 5000); // Re-try fetching tasks after a delay
	               }
	           } catch (error) {
	               console.error('Error fetching tasks:', error);
	           }
	       };
		   
		   const updateTasks = (planId, status, completedDate) => {
		       setTasks(prevTasks =>
		         prevTasks.map(task => 
		           task.plan_id === planId ? { ...task, status, completed_date: completedDate } : task
		         )
		       );
		     };
	   
			 const fetchSocialRecommendations = async () => {
			     try {
			       const response = await axios.get(`https://api.contentenhance.com/api/urls/${projectId}/${urlId}/social-recommendations`);
			       const socialRecommendations = response.data.social_recommendations;
			       setSocialRecommendations(socialRecommendations);
			     } catch (error) {
			       console.error('Error fetching social recommendations:', error);
			     }
			   };
			   
			   const fetchUrlData = async () => {
			     try {
			       const response = await axios.get(`https://api.contentenhance.com/api/urls/${projectId}/${urlId}`, {
			         withCredentials: true
			       });
			       console.log("API response:", response.data);
			       setUrlData(response.data);
			       setIsScreenshotAvailable(!!response.data.latest_screenshot);
			     } catch (error) {
			       console.error('Error fetching URL data:', error);
			       if (error.response) {
			         if (error.response.status === 401) {
			           navigate('/login');
			         } else if (error.response.status === 403) {
			           console.error('Forbidden access:', error.response.data.error);
			           setErrorMessage('You do not have permission to access this resource.');
			         }
			       }
			     }
			   };
			   

	
			   useEffect(() => {
			     fetchUrlData();
			   }, [projectId, urlId]);
	   
	   
   // useEffect to fetch tasks on component mount and projectId or urlId change
       useEffect(() => {
           fetchTasks(); // Now just directly calling the function defined at the component level
		   fetchSocialRecommendations();
       }, [projectId, urlId]);
	   
	   // New useEffect hook to update `isScreenshotAvailable`
	   useEffect(() => {
	       if (urlData) {
	           setIsScreenshotAvailable(!!urlData.latest_screenshot);
	       }
	   }, [urlData]);
	 
	 
	 
	 if (!urlData ||  tasks.length === 0  || !isScreenshotAvailable) {
		 if (!loadingTimedOut) {
	 		        return <LoadingScreen message="Fetching recommendations for your URL - check back soon" />;
				}
	 		    }
		   const hasSankeyData = urlData.top_next_urls && urlData.top_next_urls.length > 0 &&
		       ((urlData.top_referring_url && urlData.top_referring_url.length > 0) ||
		        (urlData.top_source_medium && urlData.top_source_medium.length > 0));
	 
			    

    return (
        <>
            <div className="flex flex-col h-screen bg-darkBlue">
                <TopBar />
                <div className="flex h-screen bg-lighterDarkBlue text-white">
                    <Sidebar />
                    <div className="flex-1 p-10 overflow-auto">

<div className="mb-10">
<h1 className="text-base mb-2">URL: {urlData.url}</h1>
		{urlData && urlData.redirects && urlData.redirects.length > 0 && (
		    <p className="text-sm ml-6 text-gray-300"><ArrowForwardIosIcon style={iconStyle} />Redirects to:{" "} 
		         {urlData.redirects.map((redirect, index) => (
		            <span key={index}>
		                {redirect.destination_url}{index < urlData.redirects.length - 1 ? ', ' : ''}
		            </span>
		        ))}
		    </p>
		)}
		</div>
		{showMobileModal && (
		        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
		          <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
		            <button 
		              onClick={() => setShowMobileModal(false)} 
		              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
		            >
		              &times;
		            </button>
		            <h2 className="text-center text-gray-200 text-lg z-[1300]">Scroll right for full data</h2>
		            <p className="text-center text-gray-400 text-sm z-[1300]">
		              The experience is currently ideally suited for larger screens, but scroll right for all the insight about your URL.
		            </p>
		            <div className="flex justify-center mt-4">
		              <button 
		                onClick={() => setShowMobileModal(false)} 
		                className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
		              >
		                Close
		              </button>
		            </div>
		          </div>
		        </div>
		      )}
		
<div className="mb-8">
  <table style={{ width: '100%', marginBottom: '30px' }} className="w-full text-sm min-w-[1200px]">

	                            <thead className="text-sm h-[50px]">
<tr className="sticky -top-10 bg-blue-900 z-10">
  <th style={{ width: '14%', position: 'relative'}} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="Number of sessions involving the URL in the last 90 days.">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <AnalyticsIcon fontSize="inherit" />
        </IconButton>
    Sessions
		</div>
  </th>
  <th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="The total length of time your website was in focus or your app was in the foreground across all sessions divided by the total number of active users">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <AccessTimeIcon fontSize="inherit" />
        </IconButton>
    Engagement Time
		</div>
  </th>
  <th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="% of sessions that are 10 seconds or more, had two or more page views or a conversion event (such as a scroll).">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <TimelineIcon fontSize="inherit" />
        </IconButton>
    Engagement Rate
		</div>
  </th>
  <th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="Top 5 organic search queries leading to the URL with % of total sessions in brackets">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <QueryStatsIcon fontSize="inherit" />
        </IconButton>
    Top Queries
		</div>
  </th>
  <th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="Top source / medium leading to the URL with % of total sessions in brackets">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <SourceIcon fontSize="inherit" />
        </IconButton>
    Top Source
		</div>
  </th>
  <th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
    <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
      <Tooltip title="Top device for users landing on the URL with % of total sessions in brackets">
        <IconButton size="small" style={{ color: 'grey' }} >
          <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
        </IconButton>
      </Tooltip>
    </div>
		<div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <ImportantDevicesIcon fontSize="inherit" />
        </IconButton>
    Top Device
		</div>
  </th>
		<th style={{ width: '14%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
		  <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
		    <Tooltip title={urlData?.reading_time !== "Not available" ? "Avg engagement time as a percentage of total estimated reading time" : "Top referring URL from inside the website, with % of total sessions in brackets"}>
		      <IconButton size="small" style={{ color: 'grey' }} >
		        <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
		      </IconButton>
		    </Tooltip>
		  </div>
		  <div className="flex items-center justify-center gap-x-0.5">
		    <IconButton size="small" style={{ color: 'white' }}>
		      {urlData?.reading_time !== "Not available" ? (
		        <DonutLargeIcon fontSize="inherit" />
		      ) : (
		        <CallSplitIcon fontSize="inherit" />
		      )}
		    </IconButton>
		    {urlData?.reading_time !== "Not available" ? "Content Consumed" : "Top Referring URL"}
		  </div>
		</th>
</tr>

	                            </thead>
									                            <tbody>
			
										    <tr key={urlData.url_id} style={oddRowStyle}
							
											 className="h-[150px] pt-12 pb-12">
										        
			
								        <td className="text-center text-sm pr-4 pl-4 pt-4 pb-10">
										    {urlData?.summary_metrics?.sessions ? (
										            <>
										                {urlData.summary_metrics.sessions.toLocaleString()}
														<hr className="my-2 mx-auto w-2/3" />
										                
										                <div className="w-[100px] h-[50px] mx-auto">
										                    <Sparklines data={urlData.daily_metrics.map(metric => metric.sessions)} width={100} height={50}>
										                        <SparklinesLine color="#ADD8E6" />
										                    </Sparklines>
													<div className={`text-xs ${urlData.summary_metrics.percentage_difference_sessions > 0 ? 'text-green-200' : urlData.summary_metrics.percentage_difference_sessions < 0 ? 'text-red-200' : 'text-gray-200'}`}>
														{
														  isNaN(urlData.summary_metrics.percentage_difference_sessions)
														    ? 'N/A'
														    : `${urlData.summary_metrics.percentage_difference_sessions > 0 ? '+' : ''}${Number(urlData.summary_metrics.percentage_difference_sessions * 100).toFixed(0)}% vs avg`
														}
													</div>
		                    
										                </div>
										            </>
										        )
										        : "No Data" // This is what will be displayed if sessions are zero
										    }
								        </td>
										  <td className="text-center text-sm pr-4 pl-4 pt-4 pb-10">
										    {urlData?.summary_metrics?.engagement_time ? (
										        <>
												{Math.round(urlData.summary_metrics.engagement_time).toLocaleString()} seconds
				
										            <hr className="my-2 mx-auto w-2/3" />
										            <div className="w-[100px] h-[50px] mx-auto">
										                <Sparklines data={urlData.daily_metrics.map(metric => metric.engagement_time)} width={100} height={50}>
										                    <SparklinesLine color="#CDA2D3" />
										                </Sparklines>
											<div className={`text-xs ${urlData.summary_metrics.percentage_difference_time > 0 ? 'text-green-200' : urlData.summary_metrics.percentage_difference_time < 0 ? 'text-red-200' : 'text-gray-200'}`}>
												{
												  isNaN(urlData.summary_metrics.percentage_difference_time)
												    ? 'N/A'
												    : `${urlData.summary_metrics.percentage_difference_time > 0 ? '+' : ''}${Number(urlData.summary_metrics.percentage_difference_time * 100).toFixed(0)}% vs avg`
												}
											</div>
												</div>
				
										        </>
										    ) : (
										        <div>No Data</div>
										    )}
										  </td>
										  <td className="text-center text-sm pr-4 pl-4 pt-4 pb-10">
							   		     {urlData?.summary_metrics?.engagement_rate ? (
							   		        <>
							   		            {(urlData.summary_metrics.engagement_rate * 100).toFixed(0)}%
				
							   		            <hr className="my-2 mx-auto w-2/3" />
							   		            <div className="w-[100px] h-[50px] mx-auto">
							   		                <Sparklines data={urlData.daily_metrics.map(metric => metric.engagement_rate * 100)} width={100} height={50}>
							   		                    <SparklinesLine color="#FFFFE0" />
							   		                </Sparklines>
							   			 <div className={`text-xs ${urlData.summary_metrics.percentage_difference_rate > 0 ? 'text-green-200' : urlData.summary_metrics.percentage_difference_rate < 0 ? 'text-red-200' : 'text-gray-200'}`}>
							   				 {
							   				   isNaN(urlData.summary_metrics.percentage_difference_rate)
							   				     ? 'N/A'
							   				     : `${urlData.summary_metrics.percentage_difference_rate > 0 ? '+' : ''}${Number(urlData.summary_metrics.percentage_difference_rate * 100).toFixed(0)}% vs avg`
							   				 }
			    
							   			 </div>

				
							   		            </div>
							   		        </>
							   		    ) : (
							   		        <div>No Data</div>
							   		    )}
										  </td>
									<td className="text-center pr-4 pl-4 pt-10 pb-10 max-w-xs">
									    {urlData.top_search_queries && urlData.top_search_queries.length > 0 ? (
									        urlData.top_search_queries.filter(query => query.clicks > 0).length > 0 ? (
									            (() => {
									                const seenQueries = new Set();
									                return urlData.top_search_queries
									                    .filter(query => query.clicks > 0) // Exclude queries with 0 clicks
									                    .map((query, idx) => {
									                        if (!seenQueries.has(query.query)) {
									                            seenQueries.add(query.query);
									                            return (
									                                <div key={idx}>
									                                    {query.query} ({(query.click_percentage).toFixed(0)}%)
									                                </div>
									                            );
									                        }
									                        return null;
									                    });
									            })()
									        ) : (
									            <div>No Data</div> // Show "No Data" if all queries have 0 clicks
									        )
									    ) : (
									        <div>No Data</div> // Show "No Data" if there are no queries
									    )}
									</td>
									  
									  
										  <td className="text-center pr-4 pl-4 pt-10 pb-10">
										{
										  urlData.top_source_medium && urlData.top_source_medium.length > 0 ? (
										    <div>
										      {(() => {
										        // Filter out entries where the source_medium is 'Other'
										        const filteredSourceMedium = urlData.top_source_medium.filter(entry => entry.source_medium !== 'Other');

										        if (filteredSourceMedium.length > 0) {
										          // Use the first element from the filtered array
										          return (
										            <>
										              {filteredSourceMedium[0].source_medium}
										              <div className="text-xs">
										                ({filteredSourceMedium[0].session_percentage.toFixed()}%)
										              </div>
										            </>
										          );
										        } else {
										          return "No Data";  // Return "No Data" if all entries were 'Other'
										        }
										      })()}
										    </div>
										  ) : (
										    <div>No Data</div>
										  )
										}
										  </td>
										        <td className="text-center pr-4 pl-4 pt-10 pb-10">
										            {urlData.top_device ? (
										                <div>
										                    {urlData.top_device.device_name}
															<div className="text-xs">({urlData.top_device.session_percentage.toFixed(0)}%)</div>
										                </div>
										            ) : (
										                <div>No Data</div>
										            )}
										        </td>
													{urlData?.reading_time !== "Not available" ? (
													  <td className="text-center text-sm pr-4 pl-4 pt-4 pb-4">
													    {urlData?.summary_metrics?.engagement_time ? (
													      <>
													        <div className="w-[75px] h-[75px] mx-auto">
													          <ReadingTimeChartComponent
													            percentage={Math.min(
													              (urlData.summary_metrics.engagement_time / urlData.reading_time) * 100,
													              100
													            )}
													          />
													        </div>
													   <div className="text-xs text-white mt-2">
													            {`${Math.min(
													              (urlData.summary_metrics.engagement_time / urlData.reading_time) * 100,
													              100
													            ).toFixed(0)}% of page content consumed on average`}
													          </div>
													      </>
													    ) : (
													      <div>No data</div>
													    )}
													  </td>
													) : (
													  <td className="text-center max-w-xs pr-4 pl-4 pt-10 pb-10 break-words">
													    {urlData.top_referring_url && urlData.top_referring_url.length > 0 ? (
													      <div>
													        {(() => {
													          const filteredUrls = urlData.top_referring_url.filter(urlEntry => urlEntry.referring_url !== 'Other');
													          if (filteredUrls.length > 0) {
													            let url = filteredUrls[0].referring_url.replace(/^(https?:\/\/)/, '').split('?')[0];
													            return (
													              <div className="break-words">
													                {url}
													                <div className="text-xs">
													                  ({filteredUrls[0].session_percentage.toFixed(0)}%)
													                </div>
													              </div>
													            );
													          } else {
													            return "No Data";
													          }
													        })()}
													      </div>
													    ) : (
													      <div>No Data</div>
													    )}
													  </td>
													)}
													
												  
												
										        
                
										    </tr>
									                            </tbody>
  </table>
</div>
												
													{urlData.daily_metrics && urlData.daily_metrics.length > 0 && (
													    <div className="p-8 mb-8 min-w-[1200px]">
													        <div className="flex text-sm">
													            <div
													                style={activeTab === 'engagement' ? activeTabStyle : tabStyle}
													                onClick={() => setActiveTab('engagement')}
													            >
													                <IconButton size="small" style={{ color: 'white' }}>
													                    <GroupsIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
													                </IconButton>
													                Engagement Metrics
													            </div>
													            <div
													                style={activeTab === 'journey' ? activeTabStyle : tabStyle}
													                onClick={() => setActiveTab('journey')}
													            >
													                <IconButton size="small" style={{ color: 'white' }}>
													                    <AirIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
													                </IconButton>
													                Full Journey Flow
													            </div>
													            <div
													                style={activeTab === 'referrers' ? activeTabStyle : tabStyle}
													                onClick={() => setActiveTab('referrers')}
													            >
													                <IconButton size="small" style={{ color: 'white' }}>
													                    <SendIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
													                </IconButton>
													                Referrers
													            </div>
													            <div
													                style={activeTab === 'destinations' ? activeTabStyle : tabStyle}
													                onClick={() => setActiveTab('destinations')}
													            >
													                <IconButton size="small" style={{ color: 'white' }}>
													                    <StartIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
													                </IconButton>
													                Next Destinations
													            </div>
													        </div>

													        {/* Graphs Section with consistent background */}
													        {activeTab === 'engagement' && urlData.daily_metrics.length > 0 && (
													            <div className="h-[280px] w-[100%] mx-auto pb-8">
													                <h2 className="text-base mb-4 mt-8 text-center">URL Engagement vs. Tasks Completed</h2>
													                <BarChartComponent
													                    engagementData={urlData.daily_metrics}
													                    completedTasks={tasks}
													                    organicData={urlData.daily_organic_metrics}
													                    internalData={urlData.daily_internal_metrics}
													                />
													            </div>
													        )}
															{activeTab === 'journey' && (
															    hasSankeyData ? (
															        <div className="w-full flex pb-4 min-w-[1200px]">
															            <SankeyDiagramComponent
															                url={urlData.url}
															                nextUrls={urlData.top_next_urls}
															                topReferringUrls={urlData.top_referring_url}
															                topSourceMediums={urlData.top_source_medium}
															                totalSessions={urlData.summary_metrics.sessions}
															            />
															        </div>
															    ) : (
															        <div className="text-center text-gray-400 mt-8 min-w-[1200px]" style={{ height: 280 }}>Not enough data available for Full Journey Flow</div>
															    )
															)}
													        {activeTab === 'referrers' && <ReferralSourcesOverTime urlId={urlId} />}
													        {activeTab === 'destinations' && <NextDestinationsOverTime urlId={urlId} />}
													    </div>
													)}

													                        
																   <div className="flex text-sm ml-8 min-w-[1200px]">
																       <div
																           style={activeComponentTab === 'inspiration' ? activeTabStyle : tabStyle}
																           onClick={() => setActiveComponentTab('inspiration')}
																       >
																           <IconButton size="small" style={{ color: 'white' }}>
																               <LightbulbIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
																           </IconButton>
																           Inspiration
																       </div>
																       <div
																           style={activeComponentTab === 'taskManager' ? activeTabStyle : tabStyle}
																           onClick={() => setActiveComponentTab('taskManager')}
																       >
																           <IconButton size="small" style={{ color: 'white' }}>
																               <AssignmentIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
																           </IconButton>
																           Task Manager
																       </div>
																		   
																       <div
																           style={activeComponentTab === 'screenshots' ? activeTabStyle : tabStyle}
																           onClick={() => setActiveComponentTab('screenshots')}
																       >
																           <IconButton size="small" style={{ color: 'white' }}>
																               <CameraAltIcon fontSize="inherit" style={{ marginRight: '4px', verticalAlign: 'middle' }} />
																           </IconButton>
																           Screenshot Calendar
																       </div>
																		   
																   </div>
																		   {activeComponentTab === 'inspiration' && (
																			   <div className="mb-10">
																		       <PageOverview
																		           tasks={tasks}
																		           screenshots={urlData}
																		           projectId={projectId}
																		           urlIds={[urlId]}
																		           projectData={projectData}
																		           updateProjectData={updateProjectData}
																		           fetchTasks={fetchTasks}
																		           socialRecommendations={socialRecommendations}
																				   loadingTimedOut={loadingTimedOut} // add this line
																				   fetchUrlData={fetchUrlData} // <-- Pass this prop
																		           key={tasks.length}
																		       />
																				   </div>
																		   )}
													{activeComponentTab === 'taskManager' && (
														<div className="mb-10 mt-4">
														<Checklist tasks={tasks} updateTasks={updateTasks} urlId={urlId} />
														</div>
														)}
														
														
														{activeComponentTab === 'screenshots' && (
															<div className="mb-10 mt-4 min-w-[1200px]">
                            <h2 className="text-white text-base text-center">Screenshot calendar</h2>
		<p className="mb-2 text-center text-white text-sm">Screenshot taken on <strong>top device</strong> for sessions.</p>
                            <div className="w-[95%] mx-auto text-center">
                               <Calendar screenshots={urlData.all_screenshots} />
															</div>
															</div>
															)}

                        
                 

												<Modal
												    open={isPopupOpen}
												    onClose={(_, reason) => reason === "backdropClick" && setIsPopupOpen(true)}
												    aria-labelledby="simple-modal-title"
												    aria-describedby="simple-modal-description"
												>
												    <div className={classes.paper}>
												        <h2 id="simple-modal-title">Plan Expired</h2>
												        <p id="simple-modal-description" className="text-sm">
												            Your free plan has expired after 7 days. Please upgrade to continue receiving full daily updates, such as screenshots and data storage.
												        </p>
												        <div className="mt-4">
												            <a href="https://www.contentenhance.com/pricing" className="p-2 rounded text-sm text-white bg-blue-500 hover:bg-blue-700">
												                Upgrade
												            </a>
												        </div>
												    </div>
												</Modal>
												
                        

                    </div>
                </div>
            </div>
        </>
    );
};

export default URLPage;