import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import axios from 'axios';
import { format, subDays } from 'date-fns';

const ReferralSourcesOverTime = () => {
    const { urlId } = useParams(); 
    const [data, setData] = useState([]);
    const [sortedKeys, setSortedKeys] = useState([]);
    const [hasData, setHasData] = useState(true); 

	useEffect(() => {
	        if (urlId) {
	            const fetchData = async () => {
	                try {
	                    const response = await axios.get('https://api.contentenhance.com/api/referral_data', {
	                        params: { url_id: urlId },
	                        withCredentials: true, 
	                    });
	                    const { top_referring_urls, top_source_mediums } = response.data;

	                    const combinedData = [...top_referring_urls, ...top_source_mediums]
	                        .reduce((acc, item) => {
	                            const key = item.referring_url || item.source_medium;
	                            const adjustedDate = format(subDays(new Date(item.date), 2), 'yyyy-MM-dd');
	                            const dateEntry = acc.find(entry => entry.date === adjustedDate);
	                            if (dateEntry) {
	                                if (dateEntry[key]) {
	                                    dateEntry[key] += item.sessions;
	                                } else {
	                                    dateEntry[key] = item.sessions;
	                                }
	                                dateEntry.total += item.sessions;
	                            } else {
	                                acc.push({
	                                    date: adjustedDate,
	                                    [key]: item.sessions,
	                                    total: item.sessions
	                                });
	                            }
	                            return acc;
	                        }, []);

	                    // Check and apply fallback if the sum of sessions exceeds the total
	                    combinedData.forEach(entry => {
	                        const sumOfSessions = Object.keys(entry)
	                            .filter(key => key !== 'date' && key !== 'total')
	                            .reduce((sum, key) => sum + (entry[key] || 0), 0);

	                        if (sumOfSessions > entry.total) {
	                            console.warn(`Adjusting total for ${entry.date} from ${entry.total} to ${sumOfSessions}`);
	                            entry.total = sumOfSessions; // Apply fallback
	                        }
	                    });

	                    // Sort the combined data by date
	                    combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));

	                    if (combinedData.length === 0) {
	                        setHasData(false);
	                        return;
	                    }

	                    const allKeys = new Set();
	                    combinedData.forEach(entry => {
	                        Object.keys(entry).forEach(key => {
	                            if (key !== 'date' && key !== 'total') {
	                                allKeys.add(key);
	                            }
	                        });
	                    });

	                    const formattedData = combinedData.map(entry => {
	                        const totalSessions = entry.total;

	                        // Ensure totalSessions is valid before calculating percentages
	                        if (!totalSessions || totalSessions === 0 || isNaN(totalSessions)) {
	                            console.warn(`Invalid totalSessions value for date ${entry.date}:`, totalSessions);
	                            return null; // Skip this entry if totalSessions is invalid
	                        }

	                        const percentageEntry = { date: entry.date, originalData: entry };
	                        allKeys.forEach(key => {
	                            const value = entry[key] || 0;
	                            const percentage = (value / totalSessions) * 100;
	                            // Ensure percentage is valid
	                            percentageEntry[key] = isNaN(percentage) ? 0 : parseFloat(percentage.toFixed(1));
	                        });
	                        return percentageEntry;
	                    }).filter(entry => entry !== null); // Filter out invalid entries

	                    setData(formattedData);
	                    setHasData(true);

	                    const keySums = Object.keys(formattedData[0] || {}).filter(key => key !== 'date' && key !== 'originalData')
	                        .map(key => ({
	                            key,
	                            total: formattedData.reduce((sum, entry) => sum + (entry[key] || 0), 0)
	                        }));

	                    const sortedKeys = keySums.sort((a, b) => a.total - b.total).map(item => item.key).reverse();
	                    setSortedKeys(sortedKeys);
	                } catch (error) {
	                    console.error('Error fetching referral data:', error);
	                    setHasData(false); 
	                }
	            };
	            fetchData();
	        }
	    }, [urlId]);

    return (
        <div style={{ height: 280 }} className="min-w-[1200px]">
            {hasData && data.length > 0 ? (
                <>
                    <h2 className="mt-8 text-base" style={{ textAlign: 'center', color: 'white' }}>Referral sources over time (%)</h2>
                    <ResponsiveBar
                        data={data}
                        keys={sortedKeys} 
                        indexBy="date"
                        margin={{ top: 20, right: 200, bottom: 50, left: 60 }} 
                        padding={0.3}
                        groupMode="stacked"
                        colors={{ scheme: 'pastel1' }}
                        axisTop={null}
                        axisRight={null}
						axisBottom={{
						    tickSize: 5,
						    tickPadding: 5,
						    tickRotation: -45, // Rotate labels to a diagonal position
						  
						    legendPosition: 'middle',
						    legendOffset: 32,
						    tickColor: 'white',
						    legendTextColor: 'white',
						}}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '% split over previous 90 days',
                            legendPosition: 'middle',
                            legendOffset: -50,
                            format: value => `${value}%`,
                            tickColor: 'white',
                            legendTextColor: 'white',
                            max: 100 
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor="black" 
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 170,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 180, 
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                textColor: 'white',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        tooltip={({ id, value, indexValue, data }) => {
                            const originalValue = data.originalData[id];
                            return (
                                <div style={{ color: 'white', background: 'black', padding: '5px', borderRadius: '3px',  fontSize: '12px' }}>
                                    <strong>{id}</strong><br />
                                    {indexValue}: {originalValue} sessions<br />
                                    ({value}%)
                                </div>
                            );
                        }}
                        theme={{
                            axis: {
                                ticks: {
                                    text: { fill: 'white' }, 
                                },
                                legend: {
                                    text: { fill: 'white' }, 
                                },
                            },
                            grid: {
                                line: {
                                    stroke: 'none', 
                                },
                            },
                            legends: {
                                text: { fill: 'white' }, 
                            },
                            tooltip: {
                                container: {
                                    background: 'black', 
                                    color: 'white', 
                                    fontSize: '12px', 
                                },
                            },
                        }}
                        role="application"
                        ariaLabel="Referral sources over time stacked bar chart"
                        barAriaLabel={e => `${e.id}: ${e.formattedValue}% sessions on ${e.indexValue}`}
                    />
                </>
            ) : (
                <div className="text-center text-gray-400 mt-8">Not enough data available for Referral Sources</div>
            )}
        </div>
    );
};

export default ReferralSourcesOverTime;
